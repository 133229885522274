.typography-sub {
  font-size: 10px;
  letter-spacing: 1.4px;
}

.contact .typography-normal {
  font-size: 14px;
}

.typography-medium {
  font-size: 14px;
}

.typography-normal {
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 1.4;
}

.typography-500 {
  font-weight: 500;
}

.typography-large {
  font-size: 24px;
}

.typography-disclaimer {
  display: block;
  font-size: 9px;
}

.typography-black {
  color: var(--font-black);
}

.typography-grey {
  color: var(--font-grey);
}
