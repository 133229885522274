// responsive classes
.show-mobileTablet-only {
  @media screen and (min-width: 960px) {
    display: none !important;
  }
}

// Screen size variables
$screen-sm-min: 600px; // Small tablets and large smartphones (landscape view)
$screen-md-min: 960px; // Small tablets (portrait view)
$screen-lg-min: 1240px; // Large Desktop Screens (1080p+)

// Mixins
@mixin smOnly {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin smPlus {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small devices
@mixin mdPlus {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Medium devices
@mixin lgPlus {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Large devices

@mixin matIconSize($size) {
  font-size: $size + px;
  height: $size + px;
  width: $size + px;
}
