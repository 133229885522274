@use 'mat-design/md-overrides';
@use 'global/global-overrides';
@use 'typography/typography';
@use 'utilities/utilities';
@use 'themes/themes';
@import './node_modules/ngx-sharebuttons/themes/default';

// Font Awesome Fonts
@font-face {
  font-family: 'Font Awesome 6 Brands';
  src: url('../assets/fonts/fa-light-300.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Font Awesome 6 Brands';
  src: url('../assets/fonts/fa-regular-400.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: GeometosNeue;
  src: url('../assets/fonts/GeometosNeue.woff2') format('woff2');
}

@font-face {
  font-family: GeometosNeueLight;
  src: url('../assets/fonts/geometosneuelight.woff2') format('woff2');
}

@font-face {
  font-family: GeometosNeueBold;
  src: url('../assets/fonts/GeometosBold.woff2') format('woff2');
}

@font-face {
  font-family: FreightBig;
  src: url('../assets/fonts/FreightBigBook.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: FreightText;
  src: url('../assets/fonts/freighttextbook.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Benton;
  src: url('../assets/fonts/BentonSansMedium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Benton-Book;
  src: url('../assets/fonts/BentonSansBook.woff2') format('woff2');
  font-display: swap;
}
