@use '../colors.scss';
@use '../variables/variables';

.cdk-global-scrollblock {
  position: initial !important;
  width: initial !important;
  overflow: hidden !important;
  top: 0 !important;
}

.cdk-overlay-pane {
  .mat-autocomplete-panel {
    padding: 0 16px !important;
    transform: translate(24px, -10px);

    @include variables.smPlus {
      transform: translate(0, 0);
    }
  }
}

mat-button-toggle {
  min-width: 43px;
  height: 32px;

  button,
  span {
    height: 100%;
    line-height: 1.2 !important;
  }

  .mat-button-toggle-label-content {
    line-height: 1.2 !important;
    display: grid;
    place-content: center;
  }
}

mat-optgroup {
  display: block;
  padding: 16px 0 12px;
  margin: 0 8px 0 0;
  border-bottom: 1px solid #e9e9e9;

  label {
    height: 32px !important;
    padding: 0 !important;
    text-transform: uppercase !important;
    margin: 0 !important;
    font: 500 10px/32px Roboto, 'Helvetica Neue', sans-serif !important;
    letter-spacing: 1.4px !important;
  }

  .search-input-checkbox {
    width: 100%;

    label {
      display: block;
      width: 100%;

      .mat-checkbox-label {
        line-height: 34px;
        letter-spacing: normal;
        text-transform: initial;
        font-size: 16px;
        font-weight: normal;
        transform: translateY(3px);
        display: inline-block;
      }
    }
  }

  mat-option {
    font: 400 16px/32px Roboto, 'Helvetica Neue', sans-serif !important;
    height: 32px !important;
    line-height: 32px !important;
    padding: 0 !important;
    color: #2b2e30;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    &:not(:last-of-type) {
      margin-bottom: 4px !important;
    }
  }
}

// this class added to modals via service call
.website-preview {
  mat-dialog-container {
    padding: 0;
  }
}

mat-option {
  font-family: 'Roboto-Medium', sans-serif !important;

  &.mat-option.mat-selected:not(.mat-option-multiple),
  &.mat-option:hover:not(.mat-option-disabled) {
    background-color: colors.$mist !important;
  }
}

.mls-selection-modal-dropdown {
  padding: 16px 0 !important;
  overflow: auto !important;
  transform: translate(24px, 20px) !important;

  @include variables.smPlus {
    transform: translate(0, 0) !important;
  }
}

html > * {
  .chip-dropdown {
    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0 !important;
    }

    .mat-form-field-label-wrapper {
      top: -1.5em;
    }

    .mat-select-value {
      transform: translateY(-0.3em);
    }
  }

  .chip-select {
    position: absolute !important;
    width: 85% !important;
    min-width: calc(380% + 32px) !important;
    transform: translateX(-172px) !important;

    @media screen and (min-width: 960px) {
      transform: translateX(0);
    }

    .mat-standard-chip {
      padding-left: 8px;
      padding-bottom: 8px;
    }
  }

  app-chips-ungrouped {
    mat-chip-list.full .mat-chip-list-wrapper {
      grid-template-columns: max-content;
      grid-gap: 0;
    }

    .mat-chip-list-wrapper {
      margin: 0;
      grid-template-columns: repeat(auto-fit, 95px);
      grid-gap: 4px;
      display: grid;

      & mat-chip {
        margin: 0 !important;
      }
    }
  }

  .chips-grouped {
    background-color: #f2f2f2 !important;
    color: #2b2e30;
    padding-left: 8px !important;

    .mat-select-arrow {
      opacity: 0;
    }
  }

  .button-tooltip {
    margin-bottom: 6px;
    font-size: 14px;
    background-color: colors.$charcoal;
  }

  .tp-tooltip {
    margin: 0 0 40px 8px;
  }

  &:not(.report-container) {
    &#chart-container-downloadAxisGroup1Inside,
    &#chart-container-downloadAxisGroup1Outside,
    &#chart-container-download_ChartAreaBorder,
    &#chart-container-download_ChartBorder,
    &#chart-container-downloadAxisLine_0,
    &#chart-container-download_MajorTickLine_0_0,
    &#chart-container-download_MajorTickLine_0_1,
    &#chart-container-download_MajorTickLine_0_2,
    &#chart-container-download_MajorTickLine_0_3,
    &#chart-container-download_MajorTickLine_0_4,
    &#chart-container-download_MajorTickLine_0_5 {
      display: none;
    }
  }
}

mat-radio-group {
  padding: 0 16px;
  box-sizing: border-box;
}

.mat-radio-group {
  display: flex;
  flex-direction: column;
  margin: 0;

  .mat-radio-button {
    .mat-radio-ripple {
      left: calc(50% - 16px);
      top: calc(50% - 16px);
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }
  }
}

app-user-settings-input-field,
.mat-expansion-panel,
app-contact-panel {
  .mat-checkbox-layout {
    padding: 0 !important;
  }

  .mat-checkbox,
  .mat-radio-button,
  .mat-list-option {
    height: 50px;
    display: flex !important;
    align-items: center;
    padding: 0;

    &:focus {
      outline: none;
    }

    .mat-checkbox-layout,
    .mat-radio-label {
      margin: 0;
      padding-right: 8px;
      width: 100%;
      align-items: center;
    }

    .mat-checkbox-inner-container {
      margin: auto 0;
    }

    .mat-checkbox-frame,
    .mat-radio-outer-circle,
    .mat-pseudo-checkbox:not(.mat-pseudo-checkbox-checked) {
      border-width: 2px;
      background: colors.$snow;
    }
  }
}

.mat-expansion-panel {
  box-shadow: none !important;
  border-radius: 0 !important;
  margin: 0 !important;

  .mat-radio-button {
    background: var(--font-lightgrey);
  }

  &:not(.option-header) {
    .mat-expansion-panel-body {
      border-bottom: none;
    }
  }

  .mat-expansion-panel-body {
    background: #f9f9f9;
    padding: 0 !important;
    text-align: left;
    border-bottom: 1px solid #e9e9e9;
  }

  .mat-list-base {
    padding-top: 0 !important;
  }

  .panel-section {
    border-bottom: 1px solid #e9e9e9;
  }

  .mat-checkbox-disabled {
    background-color: #fafafa;

    .mat-checkbox-label {
      color: rgba(#727476, 0.5) !important;
    }
  }

  .mat-checkbox-label,
  .mat-radio-label-content,
  .mat-list-text {
    color: #2b2e30;
    font-size: 16px;
    line-height: 24px;
    user-select: none;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding-left: 12px !important;
    width: 100%;
  }

  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    min-width: 100%;
    max-width: 100%;
    padding: 0;

    .mat-checkbox-label {
      padding-left: 12px !important;
    }
  }
}

app-filter-panel {
  &.show-small-menu {
    app-card-controls {
      .sort {
        flex-direction: column;
        padding: 16px;

        mat-form-field {
          &.card-controls-sort {
            margin-bottom: 16px;
          }

          &.card-controls-sort,
          &.card-controls-paging {
            width: 100%;
          }
        }
      }
    }
  }

  mat-expansion-panel {
    &.option-header {
      padding: 0 !important;
      height: auto !important;
    }

    mat-expansion-panel-header {
      &.market-header {
        padding: 12px 16px !important;
        align-items: normal;
        font-weight: 500;

        .mat-expansion-indicator:after {
          transform: rotate(45deg) translate3d(-2px, -2px, 0);
        }
      }

      .mat-expansion-panel-header-title {
        color: #2b2e30;
        font-size: 16px;
        align-items: center;
      }

      // MQ-641
      .filter-header-new {
        color: colors.$midnight;
        font-size: 20px;

        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;

        .grid-right {
          margin-right: 8px;
        }
      }

      & .mat-expansion-panel-header-title.icon {
        mat-icon {
          margin-right: 16px;
          color: #727476;
          @include variables.matIconSize(20);
        }
      }

      &.active {
        & .mat-expansion-panel-header-title.icon {
          mat-icon {
            color: var(--colorPrimary);
          }
        }

        border-left: 2px solid var(--colorPrimary);

        .mat-content {
          height: 100%;
        }
      }

      padding: 0 16px !important;

      .mat-content {
        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description {
          flex: 0 0 auto;
        }

        .mat-title-secondary {
          font-size: 10px;
          color: #727476;
        }
      }

      .panel-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 20px 12px;
        gap: 0 0;

        .mat-expansion-panel-header-title {
          display: grid;
          grid-template-columns: 36px 1fr;
          grid-template-rows: 1fr;
          gap: 0 0;
          grid-template-areas: 'left right';
        }

        .grid-right {
          text-align: left;
          grid-area: right;
        }
      }
    }

    .option-header {
      background-color: #ffffff;
      height: 48px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #2b2e30;
      padding: 12px 16px;
    }
  }

  .panel-subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 64px);
    text-align: left;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    & > * {
      margin: 0;
    }

    .filter-header {
      font-family: 'Roboto-Medium', sans-serif;
      color: colors.$midnight;
      font-size: 22px;

      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0;
      text-align: left;
    }

    .clear {
      color: var(--colorPrimary);
      font-size: 12px;
      letter-spacing: 0.4px;
      cursor: pointer;
    }
  }
}

.SIR-theme {
  mat-paginator {
    background-color: #f9f9f9 !important;
  }
}

.CBR-theme {
  mat-paginator {
    background-color: #f2f2f2 !important;
  }
}

mat-paginator {
  width: fit-content;
  border-radius: 8px;
  padding: 0 8px;
  color: var(--font-grey) !important;

  .mat-paginator-container {
    @include variables.mdPlus {
      padding: 0 !important;
      justify-content: flex-start;
    }
    padding: 0 !important;
    justify-content: center;
  }
}

app-default-with-nav,
app-dashboard {
  app-chips mat-select {
    position: absolute;
    margin-left: -14px;
  }

  app-chip-label .mat-chip.mat-standard-chip::after {
    background: none;
  }

  youtube-player iframe {
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mat-form-field-appearance-outline {
    & .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-color: #727476;
    }
  }

  .mat-drawer-inner-container {
    width: 325px !important;
  }

  .mat-drawer:not(.mat-drawer-side) {
    position: fixed;
  }

  .mat-drawer-backdrop {
    position: fixed;
  }

  app-card-container,
  app-filter-panel {
    overflow: auto;
  }

  app-card-container {
    .mat-form-field:not(.mat-focused) {
      .mat-form-field-label {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .mat-form-field-outline {
      background-color: colors.$snow;
    }

    app-card-controls {
      .card-controls-sort {
        margin-right: 0;
        width: 100%;
      }

      .card-controls-paging {
        @include variables.smPlus {
          width: 120px;
        }
      }
    }
  }

  app-time-period-multi,
  app-custom-graph-page {
    .mat-form-field-appearance-outline .mat-form-field-flex {
      padding-right: 0.25em;
    }
  }

  app-card-controls,
  app-custom-graph-page,
  app-time-period-multi {
    .mat-form-field-infix {
      width: initial;
    }
  }

  app-card-controls,
  app-search,
  app-contact-panel,
  app-search-input,
  app-custom-graph-page,
  app-filter-panel {
    .mat-form-field-wrapper {
      margin: 0 !important;
      padding: 0 !important;

      .mat-button-focus-overlay {
        background: transparent !important;
      }
    }

    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0 !important;
    }

    .mat-form-field-label-wrapper {
      top: -1.5em;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.1em) scale(0.75);
      width: 133.33333%;
    }

    .mat-select-value {
      transform: translateY(-4px);
    }

    .mat-form-field-prefix .mat-icon {
      color: #727476;
    }
  }

  app-report-card-v2 {
    .Brand {
      mat-chip {
        font-weight: 400;
      }
    }
  }

  app-large-preview-container {
    mat-chip-list {
      &.desktop-chip {
        @include variables.smPlus {
          align-self: flex-end;
        }
      }
    }
  }
}

.mat-calendar-next-button.mat-button-disabled {
  display: none;

  & ~ {
    .market-eod-container {
      display: flex;
    }
  }
}

.market-eod-container {
  display: none;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  color: colors.$cherry;
  position: relative;

  a {
    color: colors.$snow;
    font-weight: 800;
  }

  .tooltiptext {
    width: 350px;
    white-space: initial;
    text-align: left;
  }
}

app-mls-selection-modal {
  .mat-checkbox-label span {
    color: #727476 !important;
    font-size: 14px !important;
    letter-spacing: 0;
    line-height: 16px !important;
    white-space: normal !important;
  }
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 8px;
  border-radius: 8px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background {
  background-color: var(--color50);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: var(--color50) !important;
}

.mat-primary .mat-slider-thumb {
  height: 20px;
  width: 20px;
  background-color: var(--colorPrimary);
  bottom: -13px;
  right: -10px;
}

mat-bar-button {
  button[disabled] {
    opacity: 1 !important;
  }

  .mat-progress-bar-buffer {
    background-color: #53a9ff;
  }
}

.matBtnProg {
  border: 1px solid var(--colorPrimary) !important;
}

mat-dialog-content {
  overflow: hidden;
}

.cdk-overlay-connected-position-bounding-box {
  // adjust where top right user menu is anchored from
  transform: translate(-24px, 20px);
  @include variables.smPlus {
    transform: translate(0, 20px);
  }
}

.report-share {
  button {
    min-width: 225px;
  }
}

tr.mat-header-row,
tr.mat-row {
  height: 30px !important;
}

mat-checkbox:last-of-type label {
  margin: 0 !important;
}

.snapshot-customization-footer,
app-export-preview-controls,
app-snapshot-card {
  button {
    .mat-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      circle {
        stroke: white !important;
      }
    }
  }
}

.active.mat-expansion-panel-header {
  border-left: none;
}

.touch {
  touch-action: auto !important;
}
