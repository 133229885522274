:root {
  --font-grey: #727476;
  --font-medgrey: #E9E9E9;
  --font-lightgrey: #F9F9F9;
  --font-white: #FFFFFF;
  --font-black: #2B2E30;
}
/* CB Color Palette */
//  Primary
$cb-blue: #1f69ff;
$cb-blue-medium: #004de6;
$cb-blue-dark: #122455;
$snow: #ffffff;

//  Secondary
$charcoal: #2a2f34;
$fog: #e1e8ed;
$midnight: #14171a;
$mist: #f9f9f9;
$storm: #99a7b3;
$thunderstorm: #657786;
$visionary: #3989c9;
$pacific: #01c8c5;
$caribbean: #31cbfc;
$indigo: #3c4b72;

//  System
$cherry: #ff2f2f;
$moss: #54c029;
$posey: #ff8800;
$sunflower: #f8e71c;
$cherry-50: #ff2e2e;

// 50% Shades
$cb-blue-50: #b1cbff;
$thunderstorm-50: #b2bbc2;
$midnight-50: #8f9091;
$charcoal-50: #949799;

$cb-primary-palette: (
  50: #e2f1ff,
  100: #b9dcff,
  200: #88c7ff,
  300: #4eb1ff,
  400: #0c9fff,
  500: #1F69FF,
  600: #007dff,
  700: #1f6aff,
  800: #2a56ec,
  900: #352fcc,
  contrast: (
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A700: white,
  )
);

$cb-secondary-palette: (
  50: #e2f1ff,
  100: #b9dcff,
  200: #88c7ff,
  300: #4eb1ff,
  400: #0c9fff,
  500: #1F69FF,
  600: #007dff,
  700: #1f6aff,
  800: #2a56ec,
  900: #352fcc,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$sir-palette-primary: (
  50 : #53a6ff,
  100 : #077eff,
  200 : #0063ce,
  300 : #004086,
  400 : #003268,
  500 : #002349,
  600 : #00142a,
  700 : #00060c,
  800 : #000000,
  900 : #000000,
  A100 : #49a0ff,
  A200 : #006ce2,
  A400 : #003b7c,
  A700 : #002f62,
  contrast: (
    50 : #000000,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$sir-palette-secondary: (
  50 : #ffffff,
  100 : #ffffff,
  200 : #ffffff,
  300 : #ffffff,
  400 : #f2f4f6,
  500 : #e0e5e9,
  600 : #ced6dc,
  700 : #bcc7d0,
  800 : #aab8c3,
  900 : #98a9b6,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #fefefe,
  A700 : #eef2f4,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
