@use '../colors.scss';
@use '../variables/variables';

.full-size {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.marketquest-body {
  background-color: colors.$mist !important;

  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform, margin-left, margin-right;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.mat-button-focus-overlay {
  opacity: 0 !important;
}

.logo {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;

  img,
  source {
    width: 100%;
  }
}

.mq-container {
  min-height: 100%;
  display: inline;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.mq-loading {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  color: var(--colorPrimary);
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

html,
body {
  height: 100%;
  width: 100%;
}

* {
  font-family: Roboto, sans-serif;
  font-style: normal;
}

body {
  display: flex;
  overflow-x: hidden;
}

body {
  &.show {
    overflow-y: hidden;
  }
}

.SIR-theme {
  .illustration {
    display: none;
  }
}

// V2 Filter Panel Mat Design overrides
app-pagination {
  .page-button {
    height: auto;
    width: auto;
    padding: 6px;
    min-width: 33px;
    min-height: 33px;
    border: 1px solid #b2bbc2;
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;

    &:not(:last-child) {
      margin-right: 8px;
    }

    span {
      color: #2a2f34;

      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }

    &.active {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      border-color: var(--colorPrimary);
    }

    &:hover {
      background-color: transparent;
    }

    &.disabled {
      color: grey;
      cursor: initial;
    }
  }
}

// Skeleton loading styles
$base-color: #e7e7e7;
$shine-color: #efefef;
$animation-duration: 1.6s;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 80vw;
}

.skeleton-still {
  background: $base-color;
}

.skeleton {
  width: 100%;
  color: transparent !important;
  @include background-gradient;
  border-radius: 4px;

  &.skeleton_animate {
    animation: shine-lines $animation-duration infinite linear;

    @keyframes shine-lines {
      0% {
        background-position: -100px;
      }
      40% {
        background-position: 40vw;
      }
      100% {
        background-position: 60vw;
      }
    }
  }
}

.return-link {
  color: var(--colorPrimary);
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;

  mat-icon {
    font-size: 20px;
    padding-right: 16px;
  }

  i,
  span {
    display: block;
  }
}

app-tall-chart-download {
  @include variables.smPlus {
    #chart-container-downloadAxisGroup0Inside {
      transform: translate(-1.5px, 1px) !important;
    }
  }
}

// custom scroll bars for infinite lists
cdk-virtual-scroll-viewport,
.property-type-scroll {
  overflow-y: auto;
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 6px;
    margin: 32px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #727476;
    border-radius: 6px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// table overrides
tr > td:first-of-type > .tooltip-d .tooltiptext {
  left: 0;
  margin-left: 0;
}

tr > td:nth-of-type(7) > .tooltip-d .tooltiptext,
tr:first-of-type > td:last-of-type > .tooltip-d .tooltiptext {
  right: 0;
  margin-left: 0;
  left: auto;
}

// create tooltips
.tooltip-d .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #2b2e30;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  left: -344px;
  display: inline-block;
  white-space: nowrap;

  @include variables.mdPlus {
    align-self: flex-end;
    bottom: 36px;
    left: 0;
  }
}

.tooltip-d:hover .tooltiptext {
  visibility: visible;
}

.custom-scrollbar {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #727476;
  border-radius: 6px;
  background-clip: padding-box;
}

.custom-scrollbar {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar:vertical,
  &::-webkit-scrollbar:horizontal {
    width: 4px;
    border-radius: 6px;
  }
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e9e9e9;
}

.custom-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

.custom-scrollbar.scroll-auto-hide:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

.custom-scrollbar.scroll-auto-hide::-webkit-scrollbar-thumb {
  background: transparent;
  background-clip: padding-box;
}

.custom-scrollbar.scroll-auto-hide::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 6px;
}

.custom-scrollbar.scroll-auto-hide:hover::-webkit-scrollbar-track {
  background: #e9e9e9;
}

.custom-scrollbar.scroll-auto-hide {
  scrollbar-color: transparent transparent;
}

.custom-scrollbar.scroll-auto-hide:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.scroll-hidden {
  overflow: hidden;
}

.v-scroll-auto {
  overflow-y: auto;
}

.h-scroll-auto {
  overflow-x: auto;
}

.scrollable {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding-right: 8px;
}

.dark-overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 99;
  left: 0;
  opacity: 0;
  transition: opacity 300ms;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
}

.smallPreview {
  mat-dialog-container {
    background: transparent !important;
    box-shadow: none !important;
  }

  .export-preview {
    background-color: transparent !important;
    gap: 0 !important;

    .controls {
      flex: 1 !important;

      @include variables.smPlus {
        flex: 1 1 auto !important;
      }
    }
  }
}

.side-menu {
  height: 100vh;
  height: -webkit-fill-available;
  padding-bottom: 32px;
  overflow: hidden;
  z-index: 100;
  transition: all 300ms cubic-bezier(0.25, 0.8, 0.25, 1);
  border-right: 1px solid #e9e9e9;
  background-color: #fff;
  position: absolute;
  transform: translate3d(-101%, 0, 0);

  &.side-bar-filter {
    width: 100%;
    top: 0;
    @include variables.smPlus {
      width: 329px;
      top: initial;
    }

    .filter-panel {
      overflow-y: auto;
      height: 100%;
      display: block;
    }
  }

  &.right {
    transform: translate3d(101%, 0, 0);
    right: 0;
  }

  &.open {
    transform: none;
    z-index: 9002;
  }
}

// hide first and last grid lines ALWAYS
.report-container {
  [id$='MajorGridLine_1_6'],
  [id$='MajorGridLine_1_0'] {
    display: none;
  }

  [id*='MajorGridLine'] {
    display: none;
    @include variables.smPlus {
      // show above mobile
      display: initial;
    }
  }
}

.cdk-overlay-pane.export {
  mat-dialog-container {
    transform: none;
    background: transparent;
    box-shadow: none;
    padding: 40px;
  }
}

ejs-chart {
  &,
  svg {
    overflow: visible !important;
  }
}

.mat-panel-header {
  font-size: 20px;
  align-items: center;
}

.mat-panel-title {
  height: 48px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  background: white;
  padding: 0 16px;
  width: 100%;
}

.cdk-overlay-container,
.mat-tooltip-panel {
  z-index: 9002 !important;
}

.tablet-filters {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin-top: 32px;

  @include variables.mdPlus {
    display: none;
  }

  .location {
    width: 100%;
  }

  .mobile-filter-toggle-btn {
    color: var(--colorPrimary);
    float: right;
    border: none;
    background: none;
    font-size: 14px;

    mat-icon {
      display: inline-block;
      transform: translateY(3px);
      margin-right: 3px;
      font-size: 16px;
      height: 16px;
      width: 16px;
    }
  }
}

app-social-modal {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .share-text {
      flex: 1;
    }
  }
}

.market-quest-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    height: 100%;
  }
}
